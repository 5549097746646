@import "../../../css-config/mixins.scss";
.product-card {
	width: calc(50% - 5px);
	margin-bottom: 20px;

	@include md {
		width: calc(25% - 15px);
	}

	.thumbnail {
		width: 100%;
		height: 180px;
		background-color: rgba(0, 0, 0, 0.05);
		margin-bottom: 10px;
		padding: 25px;
		display: flex;
		align-items: center;

		@include md {
			height: 350px;
		}
		img {
			margin-inline: auto;
			transition: all ease 0.3s;
			display: block;
			max-height: 100%;
			max-width: 100%;
		}
	}

	.product-details {
		.name {
			font-size: 14px;
			display: block;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;

			@include md {
				font-size: 16px;
				margin-bottom: 10px;
			}
		}
		.price {
			font-size: 18px;

			@include md {
				font-size: 24px;
			}
		}
	}

	&:hover {
		.thumbnail {
			img {
				transform: scale(1.2);
			}
		}
	}
}
